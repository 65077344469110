<script setup lang="ts">
import type { IResponsiveOnly } from "@magnit/core/types";
import type { ISwiperOptions } from "~/typings/components/appSlider";

const { partnersSectionEnabled } = usePublicConfig();

const mainSliderBreakpoints: IResponsiveOnly<ISwiperOptions> = {
  xs: {
    slidesPerView: "auto",
    spaceBetween: 8,
    pagination: {
      enabled: true,
    },
    slidesOffsetAfter: 12,
    slidesOffsetBefore: 12,
  },
  s: {
    slidesPerView: "auto",
    spaceBetween: 12,
    slidesOffsetAfter: 20,
    slidesOffsetBefore: 20,
  },
  m: {
    slidesPerView: "auto",
    spaceBetween: 20,
    slidesOffsetAfter: 32,
    slidesOffsetBefore: 32,
  },
  ml: {
    slidesPerView: "auto",
    spaceBetween: 20,
    slidesOffsetAfter: 250,
    slidesOffsetBefore: 32,
  },
  l: {
    slidesPerView: "auto",
    spaceBetween: 24,
    navigation: true,
    slidesOffsetAfter: 300,
    slidesOffsetBefore: 0,
  },
  xl: {
    slidesPerView: 3,
    spaceBetween: 24,
    navigation: true,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
  },
};
</script>

<template>
  <div>
    <NuxtLayout name="default">
      <div>
        <slot />
      </div>
      <SectionsPopularSlider />
      <SectionsTicker />
      <SectionsMainSlider
        class="layout-product__slider"
        :swiper-breakpoints="mainSliderBreakpoints"
        lesser
      />
      <SectionsWeekProducts />
      <SectionsSpecialOffers />
      <SectionsNewProducts />
      <SectionsPartnersOffers v-if="partnersSectionEnabled" />
    </NuxtLayout>
  </div>
</template>

<style scoped lang="postcss">
.layout-product__slider {
  margin-top: var(--pl-unit-x6);
}
</style>
